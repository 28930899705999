<template>
  <main class="page-currencies page-currencies-edit">
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica valuta {{ currency ? currency.name : '' }}</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'settings.currencies' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <CurrencyForm v-if="currency" :value="currency" :feedback.sync="feedback" @submitForm="saveCurrency" edit />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import CurrencyForm from '@/views/components/Form/CurrencyForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    CurrencyForm,
  },
  data () {
    return {
      currency: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'settings.currencies' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveCurrency (currencyForm) {
      const currency = { ...currencyForm };

      this.$api.updateCurrency(currency.code, currency)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    $api.fetchCurrency(to.params.code)
      .then(res => {
        return next(vm => {
          vm.currency = res?.data || {};
        });
      })
      .catch(err => {
        return next(vm => vm.$log.error(err));
      })
    ;
  },
};

</script>
